import React, { useState, useEffect } from "react";
import Carousels from "../Components/Carousels";
import Card from "../Components/Card";
import { search } from "../Api";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { searchData, searchVideosData } from "../Redux/SearchSlice";
const Home = () => {
  const [word, setWord] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    const searchCategory = () => {
      search(word)
        .then((res) => {
          dispatch(searchData(res.data?.speakers));
          dispatch(searchVideosData(res.data?.videos));
        })
        .catch((error) => {});
    };
    searchCategory();
  }, [word]);
  return (
    <div>
      <Helmet>
        <title>Big Journey</title>
        <meta name="description" content="Big Journey" />
        <meta
          name="keywords"
          content="Big Journey, Hindi literature, poetry contest, Hindi poetry, literary event,videos,prodcast "
        />
        <meta name="author" content="Big Journey" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="icon"
          href="https://kavishala.com/images/favicon.png"
          type="image/x-icon"
        />

        <meta property="og:title" content="Big Journey 2023 " />
        <meta property="og:description" content="Big Journey" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://bigjourneys.in/" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Big Journey 2023 " />
        <meta name="twitter:description" content="Big Journey" />
        <meta name="twitter:image" content="" />
      </Helmet>
      <div className="d-sm-none">
        <form className="d-sm-inline-block form-inline mr-auto my-2 my-md-0 mw-100 navbar-search">
          <div className="input-group">
            <input
              type="text"
              className="form-control bg-white border-0 small"
              placeholder="Search for Movies, Events, Plays, Sports and Activities..."
              aria-label="Search"
              aria-describedby="basic-addon2"
              id="search"
              onChange={(e) => setWord(e.target.value)}
            />
            <div className="input-group-append">
              <button className="btn bg-white" type="button">
                <i className="fas fa-search fa-sm"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
      <Carousels />
      <Card />
    </div>
  );
};

export default Home;
