import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { allSpeaker } from "../Api";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
export default function Carousels() {
  const navigate = useNavigate();
  const selector = useSelector((state) => state?.searchData?.authorData);
  const [data, setData] = useState([]);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 8,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 8,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2.2,
    },
  };
  useEffect(() => {
    const allSpeakerData = () => {
      allSpeaker()?.then((res) => {
        if (selector.length === 0) setData(res?.data);
        else setData(selector);
      });
    };
    allSpeakerData();
  }, [selector]);

  console.log("res", selector);
  console.log("res2", data);
  return (
    <>
      <h6 className="category-name" style={{ margin: "15px 10px" }}>
        Our Guests
      </h6>

      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay
        ssr={true}
        autoPlaySpeed={3000}
      >
        {data?.map((value) => {
          return (
            <div
              style={{ margin: " 20px 10px" }}
              className="user-profile"
              onClick={() => navigate(`/journey/${value?.slug}`)}
            >
              <img width="100%" src={value?.image} className="user-dp" />
              <h5 className="card-title">{value?.name}</h5>
            </div>
          );
        })}
      </Carousel>
    </>
  );
}
