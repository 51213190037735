import { createSlice } from "@reduxjs/toolkit";
const initialState = { authorData: [], videos: [] };

const SearchSlice = createSlice({
  name: "searchdata",
  initialState,
  reducers: {
    searchData(state, action) {
      state.authorData = [...action.payload];
    },
    searchVideosData(state, action) {
      state.videos = [...action.payload];
    },
  },
});
export const { searchData } = SearchSlice.actions;
export const { searchVideosData } = SearchSlice.actions;
export default SearchSlice.reducer;
