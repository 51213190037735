import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { allVedioDetails } from "../Api";
import { useParams } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
const ProfileDetails = () => {
  const [data, setData] = useState();
  const { slug } = useParams();
  useEffect(() => {
    const allSpeakerData = () => {
      allVedioDetails(slug)
        .then((res) => {
          setData(res?.data);
        })
        .catch((error) => {});
    };
    allSpeakerData();
  }, [slug]);
  return (
    <div className="books_details-conatiner">
      <div className="book-details">
        <ReactPlayer
          url={data?.video_link}
          controls={true}
          width="100%"
          height="400px"
          style={{ borderRadius: "5px", left: "0" }}
        />
      </div>
      <div className="book_details_down_container">
        <div className="books_left">
          <h3 className="books_names"> Name: {data?.name}</h3>

          {/* <h3 className="books_names">
            Publication Year:{" "}
            {formatDistanceToNow(
              new Date(data?.created_at ? data?.created_at : ""),
              {
                addSuffix: true,
              }
            )}
          </h3> */}
          <h3 className="books_despcriptions"> Description:</h3>
          <p
            className="single_books_details_desp"
            dangerouslySetInnerHTML={{
              __html: data?.description,
            }}
          />
        </div>
        <div className="books_right">
          <img
            src={data?.thumbnail_image}
            alt=""
            className="book_details_image"
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
