import React, { useState, useEffect } from "react";
import Video_details from "../Components/Video_details";
import Carousels from "../Components/Carousels";
import Card from "../Components/Card";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { allSpeakerDetails, allVedioDetails } from "../Api";
const VideoDetails = () => {
  const { slug } = useParams();
  const [data, setData] = useState([]);
  const [videoData, setVideoData] = useState();
  useEffect(() => {
    const loadData = () => {
      allSpeakerDetails(slug)
        .then((res) => {
          if (res?.status === 200) {
            console.log("res", res);
            setData(res?.data);
            setVideoData(res.data?.video_link);
          }
        })
        .catch((error) => {});
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Optional smooth scrolling
      });
    };
    loadData();
  }, [slug]);

  useEffect(() => {
    const allSpeakerData = () => {
      allVedioDetails(slug)
        .then((res) => {
          setData(res?.data);
          res?.data?.videos?.map((value) => {
            if (slug === value?.slug) {
              setVideoData(value?.video_link);
            }
          });
        })
        .catch((error) => {});
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Optional smooth scrolling
      });
    };
    allSpeakerData();
  }, [slug]);
  console.log("data", data);
  return (
    <div>
      <Helmet>
        <title>
          {data?.name ? `Big Journeys | ${data?.name}` : "Big Journeys "}
        </title>
        <meta name="description" content={data?.description} />
        <meta
          name="keywords"
          content="Big Journey, Hindi literature, poetry contest, Hindi poetry, literary event,videos,prodcast "
        />
        <meta name="author" content={`Big Journeys ${data?.name}`} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="icon" href={data?.image} type="image/x-icon" />

        <meta property="og:description" content={data?.description} />
        <meta property="og:url" content={data?.video_link} />
        <meta property="og:site_name" content="" />
        <meta property="og:image" content={data?.image} />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="400" />
        <meta property="og:image:alt" content={data?.image} />
        <meta property="article:publisher" content={data.description} />
        <meta property="article:section" content={data?.description} />
        <meta property="article:published_time" content={data?.created_at} />

        <meta property="og:title" content={data?.name} />
        <meta property="og:description" content={data?.description} />
        <meta property="og:image" content={data?.image} />
        <meta property="og:url" content={`/journey/${data.slug}`} />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={data?.name} />
        <meta name="twitter:description" content={data?.description} />
        <meta name="twitter:image" content={data?.image} />
      </Helmet>
      <Video_details
        setData={setData}
        setVideoData={setVideoData}
        data={data}
        videoData={videoData}
      />
      <Carousels />
      <Card />
    </div>
  );
};

export default VideoDetails;
