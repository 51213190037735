import React from "react";
import ProfileDetails from "../Components/ProfileDetails";
import { Helmet } from "react-helmet";
const CardDetails = () => {
  return (
    <div>
      <Helmet>
        <title>Big Journey</title>
        <meta name="description" content="Big Journey" />
        <meta
          name="keywords"
          content="Big Journey, Hindi literature, poetry contest, Hindi poetry, literary event,videos,prodcast "
        />
        <meta name="author" content="Big Journey" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="icon"
          href="https://kavishala.com/images/favicon.png"
          type="image/x-icon"
        />

        <meta
          property="og:title"
          content="Big Journey 2023 - Celebrating Hindi Literature"
        />
        <meta property="og:description" content="Big Journey" />
        <meta
          property="og:image"
          content="https://kavishala.co.in/Web/Hindi-Diwas-Exam.jpg"
        />
        <meta
          property="og:url"
          content="https://kavishala.com/hindi-diwas-competition"
        />

        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="Big Journey 2023 - Celebrating Hindi Literature"
        />
        <meta name="twitter:description" content="Big Journey" />
        <meta
          name="twitter:image"
          content="https://kavishala.co.in/Web/Hindi-Diwas-Exam.jpg"
        />
      </Helmet>
      <ProfileDetails />
    </div>
  );
};

export default CardDetails;
