import { configureStore } from "@reduxjs/toolkit";
import SearchSlice from "./SearchSlice";

const store = configureStore({
  reducer: {
    searchData: SearchSlice,
    searchVideosData: SearchSlice,
  },
});
export default store;
