import React from "react";
import "../Footer/Footer.scss";
import XIcon from "@mui/icons-material/X";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Link } from "react-router-dom";
import IMDB from "../../Images/IMDB_Logo.png";

function ChapterLinks({ chapters }) {
  return (
    <div className="container-fluid">
      <div className="row">
        {chapters.map((chapter) => {
          return (
            <div className="col-3 col-lg-1">
              <Link
                legacyBehavior
                to={`/chapters/${chapter.toLowerCase()}-chapter`}
                className="terms_word"
              >
                {chapter}
              </Link>
            </div>
          );
        })}
      </div>
      <style jsx>
        {`
          a {
            color: #ccc;
            font-weight: normal;
          }
          a:hover {
            text-decoration: unset;
            color: #fff;
          }
        `}
      </style>
    </div>
  );
}
const Spotify = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 496 512"
    className="icon"
    width="36px"
    height="36px"
    style={{ fill: "#4096aa" }}
    onClick={() =>
      window.open(
        "https://open.spotify.com/show/0Ysss6QTKiSCWE76yDfqMv?si=Bfj2AhhzR7i8c8zrz3CygA"
      )
    }
  >
    <path d="M248 8C111.1 8 0 119.1 0 256s111.1 248 248 248 248-111.1 248-248S384.9 8 248 8zm100.7 364.9c-4.2 0-6.8-1.3-10.7-3.6-62.4-37.6-135-39.2-206.7-24.5-3.9 1-9 2.6-11.9 2.6-9.7 0-15.8-7.7-15.8-15.8 0-10.3 6.1-15.2 13.6-16.8 81.9-18.1 165.6-16.5 237 26.2 6.1 3.9 9.7 7.4 9.7 16.5s-7.1 15.4-15.2 15.4zm26.9-65.6c-5.2 0-8.7-2.3-12.3-4.2-62.5-37-155.7-51.9-238.6-29.4-4.8 1.3-7.4 2.6-11.9 2.6-10.7 0-19.4-8.7-19.4-19.4s5.2-17.8 15.5-20.7c27.8-7.8 56.2-13.6 97.8-13.6 64.9 0 127.6 16.1 177 45.5 8.1 4.8 11.3 11 11.3 19.7-.1 10.8-8.5 19.5-19.4 19.5zm31-76.2c-5.2 0-8.4-1.3-12.9-3.9-71.2-42.5-198.5-52.7-280.9-29.7-3.6 1-8.1 2.6-12.9 2.6-13.2 0-23.3-10.3-23.3-23.6 0-13.6 8.4-21.3 17.4-23.9 35.2-10.3 74.6-15.2 117.5-15.2 73 0 149.5 15.2 205.4 47.8 7.8 4.5 12.9 10.7 12.9 22.6 0 13.6-11 23.3-23.2 23.3z" />
  </svg>
);
const Footer = () => {
  return (
    <div className="footer">
      <div className="footer_icon">
        <XIcon
          className="icon"
          onClick={() => window.open("https://x.com/bigjourneysks")}
        />
        <FacebookOutlinedIcon
          className="icon"
          onClick={() => window.open("https://www.facebook.com/bigJourneysKS/")}
        />
        <InstagramIcon
          className="icon"
          onClick={() => window.open("https://www.instagram.com/bigjourneysks")}
        />
        <WhatsAppIcon
          className="icon"
          onClick={() =>
            window.open("https://whatsapp.com/channel/0029VaKCYp84dTnLzp3MjS3p")
          }
        />

        <YouTubeIcon
          className="icon"
          onClick={() =>
            window.open(
              "https://www.youtube.com/@BigJourneysKS?sub_confirmation=1"
            )
          }
        />
        <Spotify />
      </div>

      <div className="tradmarks">
        <img
          src={IMDB}
          className="imdb-rating"
          onClick={() => window.open("https://www.imdb.com/title/tt30803028/")}
        />
      </div>
      <div className="contact-us">
        <h5 className="main-heading">Contact Us:</h5>
        <div>
          <span className="heading">For Business Query:</span>
          <a href="mailto: business@bigjourneys.in" className="link">
            {" "}
            business@bigjourneys.in
          </a>
        </div>
        <div>
          <span className="heading">Pitch Your Journey:</span>
          <a href="mailto:pitch@bigjourneys.in" className="link">
            {" "}
            pitch@bigjourneys.in
          </a>
        </div>
        <div>
          <span className="heading">Call/ Whatsapp:</span>
          <span className="heading"> +91 7982334501</span>
        </div>
      </div>
      <div className="tradmarks">
        <span className="tradmarks-name">© BigJourneys 2024</span>
      </div>
    </div>
  );
};

export default Footer;
