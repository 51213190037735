import axios from "axios";
const base_url = "https://admin.kavishala.in";

const allSpeaker = () => {
  const url = `${base_url}/big_journey/allspeakers/`;
  try {
    const response = axios.get(url).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const allVideos = () => {
  const url = `${base_url}/big_journey/allvideos/`;
  try {
    const response = axios.get(url).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const allSpeakerDetails = (slug) => {
  const url = `${base_url}/big_journey/singleapreaker/${slug}/`;
  try {
    const response = axios.get(url).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const allVedioDetails = (slug) => {
  const url = `${base_url}/big_journeys/singlevideo/${slug}/`;
  try {
    const response = axios.get(url).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const search = (word) => {
  const url = `${base_url}/big_jouneys/search/?search=${word}`;
  try {
    const response = axios.get(url).then((res) => {
      return res;
    });
    return response;
  } catch (error) {
    return error;
  }
};
export { allSpeaker, allVideos, allSpeakerDetails, allVedioDetails, search };
