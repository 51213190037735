import React, { useState, useEffect } from "react";
import "../Styles/Navbar.css";
import Dp from "../Images/favicon.ico";
import Logo from "../Images/bigJourneys.png";
import { search, allVideos } from "../Api";
import { useDispatch } from "react-redux";
import { searchData, searchVideosData } from "../Redux/SearchSlice";
import subscribe from "../Images/subscribe.png";
const Navbar = () => {
  const [word, setWord] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    const searchCategory = () => {
      search(word)
        .then((res) => {
          dispatch(searchData(res.data?.speakers));
          dispatch(searchVideosData(res.data?.videos));
        })
        .catch((error) => {});
    };
    searchCategory();
  }, [word]);

  return (
    <div id="content">
      <nav className="navbar navbar-expand navbar-dark topbar pl-0 static-top shadow">
        <div>
          <form className="d-none d-sm-inline-block form-inline mr-auto my-2 my-md-0 mw-100 navbar-search">
            <div className="input-group">
              <input
                type="text"
                className="form-control bg-white border-0 small"
                placeholder="Search for Movies, Events, Plays, Sports and Activities..."
                aria-label="Search"
                aria-describedby="basic-addon2"
                id="search"
                onChange={(e) => setWord(e?.target?.value)}
              />

              <div className="input-group-append">
                <button className="btn bg-white" type="button">
                  <i className="fas fa-search fa-sm"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
        <div
          style={{
            position: "relative",
            textAlign: "center",
          }}
        >
          <a href="https://www.youtube.com/@BigJourneysKS?sub_confirmation=1">
            <img src={subscribe} className="navbar-subscribe-image"></img>
          </a>
        </div>
        <ul className="navbar-nav ml-auto">
          {/* <li className="nav-item dropdown no-arrow d-sm-none">
            <div>
              <form className="d-sm-inline-block form-inline mr-auto my-2 my-md-0 mw-100 navbar-search">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control bg-white border-0 small"
                    placeholder="Search for Movies, Events, Plays, Sports and Activities..."
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                    id="search"
                    onChange={(e) => setWord(e.target.value)}
                  />
                  <div className="input-group-append">
                    <button className="btn bg-white" type="button">
                      <i className="fas fa-search fa-sm"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </li> */}

          <li className="nav-item dropdown no-arrow">
            <a
              className="nav-link "
              href="/"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img className="img-profile" src={Logo} />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
