import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.css";
import Navbar from "./Components/Navbar";
import Home from "./Pages/Home";
import CardDetails from "./Pages/CardDetails";
import VideoDetails from "./Pages/VideoDetails";
import Footer from "./Components/Footer/Footer";
const App = () => {
  
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="journey/:slug" element={<VideoDetails />} />
        {/* <Route path="journey/:slug" element={<CardDetails />} /> */}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
