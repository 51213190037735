import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { allSpeakerDetails, allVedioDetails, allVideos } from "../Api";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import "../Styles/Tv.scss";
const Video_details = ({ setData, setVideoData, data, videoData }) => {
  const [show, setShow] = React.useState(false);

  const navigate = useNavigate();

  const handleNext = (value) => {
    setVideoData(value);
  };

  const togglePopup = () => {
    setShow(!show);
  };
  return (
    <section className="tv-slider">
      <div className="tv_wrapper">
        <div className="tv-container">
          <div className="tv_modals">
            <ReactPlayer
              url={videoData}
              width={"100%"}
              height={"100%"}
              controls={true}
              config={{
                youtube: {
                  playerVars: {
                    modestbranding: 1, // Hide YouTube logo
                    showinfo: 0, // Hide video title and uploader info
                    rel: 0, // Do not show related videos at the end
                  },
                },
              }}
            />
            <h2 className="tv_details_heading">{data?.name}</h2>
            <p
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                className="tv_details_descriptions"
                dangerouslySetInnerHTML={{
                  __html: data?.description,
                }}
              />{" "}
              <span
                style={{
                  color: "#fff",
                  fontFamily: "roboto",
                  cursor: "pointer",
                }}
                onClick={togglePopup}
              >
                {data?.description?.length > 150 ? "more" : ""}
              </span>
            </p>
          </div>

          <div className="tv_modals_category">
            <div className="tv-main-container">
              <h3 className="tv-main-container-heading">
                {data?.category_title}
              </h3>

              {data?.videos?.map((value, index) => {
                return (
                  <ul
                    className="tv-cat-under-list"
                    onClick={() => {
                      handleNext(value?.video_link);
                    }}
                  >
                    <li className="tv-cat-list">
                      <div>
                        <img
                          src={value?.thumbnail_image}
                          className="tv-cat-list-image"
                        />
                      </div>
                      <div>
                        <h6 className="tv-cat-list-head">{value?.name}</h6>{" "}
                      </div>
                    </li>
                  </ul>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={show}
        onRequestClose={togglePopup}
        contentLabel="Example Modal"
        className="select-premium-modal"
      >
        <p
          dangerouslySetInnerHTML={{
            __html: data?.description,
          }}
          style={{
            padding: "10px",
          }}
        />
      </Modal>
    </section>
  );
};

export default Video_details;
