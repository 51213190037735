import React, { useEffect, useState } from "react";
import "../Styles/Navbar.css";
import { useNavigate } from "react-router-dom";
import { allVideos } from "../Api";
import { formatDistanceToNow } from "date-fns";
import { useSelector } from "react-redux";
const Card = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const selector = useSelector((state) => state?.searchVideosData?.videos);
  console.log("kjhvcvb", selector);
  useEffect(() => {
    const allSpeakerData = () => {
      allVideos().then((res) => {
        if (selector.length === 0) setData(res?.data);
        else setData(selector);
      });
    };
    allSpeakerData();
  }, [selector]);

  return (
    <>
      <div className="category-liner">
        <h6 className="category-name" style={{ margin: "10px" }}>
          Featured Journeys
        </h6>
        <div className="card-container">
          {data?.map((value) => {
            return (
              <div
                className="card"
                style={{ width: "16.5rem", margin: "10px" }}
                onClick={() => navigate(`/journey/${value?.slug}`)}
              >
                <img
                  className="card-img-top"
                  src={value?.thumbnail_image}
                  alt="Card image cap"
                />
                <div className="card-body">
                  <h5 className="card-title">{value?.name}</h5>
                  <div>
                    <span className="views">{value?.views} views</span>
                    <span className="views">
                      {formatDistanceToNow(new Date(value?.created_at), {
                        addSuffix: true,
                      })}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Card;
